<template>
  <div class="px-6 lg:px-0 flex items-center mt-32">
    <div class="w-full space-y-3">
      <svg
        width="57"
        height="5"
        viewBox="0 0 57 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line y1="2.5" x2="57" y2="2.5" stroke="black" stroke-width="5" />
      </svg>
      <h2 class="font-bold text-5xl">Frequently Asked Questions</h2>
      <div>
        <Disclosure question="How much does it cost to use Clarity?">
          <div>
            <p class="my-2">
              For schools, Clarity is $1,000 / year. For schools that sign up
              before July 1st, 2022, Clarity is free forever. That’s right, for
              early adopter schools, there is no direct cost to use Clarity
              ever!
            </p>
            <p class="my-2">
              For families, Clarity charges $55 to submit an application. This
              application fee covers all student applicants and includes sending
              the application to any school that accepts the Clarity
              application.
            </p>
          </div>
        </Disclosure>
        <Disclosure
          question="How hard is it to switch from my current provider?"
        >
          <p class="my-2">
            We’ve made switching easy; in fact, our team will do the work for
            you by importing your historical data so that it’s all there for you
            the first time that you log in.
          </p>
        </Disclosure>

        <Disclosure question="How secure is Clarity?">
          <p class="my-2">
            We take security incredibly seriously and have built our systems to
            be fully compliant with GDPR and SOC2 requirements. We have even
            voluntarily engaged a third party security firm to go through an
            annual security audit. This is a rigorous and expensive process that
            demonstrates our commitment to the importance of security.
          </p>
        </Disclosure>
        <Disclosure
          question="Does Clarity integrate with my enrollment / billing system?"
        >
          <p class="my-2">
            Clarity makes it easy for you to do your awarding in software and to
            export that data in a way that it can be easily imported into your
            enrollment and billing software. Clarity maintains an open API and
            believes that all companies should support cross-vendor
            interoperability. If there’s a product or company that you would
            like us to integrate with, please reach out to them.
          </p>
        </Disclosure>
        <Disclosure question="How easy is it to learn the software?">
          <p class="my-2">
            Pretty easy! We have a variety of video tutorials and training
            materials that walk you through each aspect of the software and our
            onboarding team is available to help answer any questions.
          </p>
        </Disclosure>
        <Disclosure question="How do you handle support?">
          <p class="my-2">
            We aim to be a true partner, not just a product. Each school partner
            has a dedicated client success manager that leads you through
            onboarding and implementation and acts as an extension of your team
            throughout the year. For families, we offer in-app support as well
            as email and phone support.
          </p>
        </Disclosure>
        <Disclosure
          question="How long does it take to complete the Clarity application? "
        >
          <p class="my-2">
            The Clarity application scales in complexity depending on the
            complexity of the household’s financial circumstances. With that
            said, the vast majority of families can complete the application in
            15 minutes or less.
          </p>
        </Disclosure>
      </div>
    </div>
  </div>
</template>

<script>
import Disclosure from "./Disclosure.vue";

export default {
  components: { Disclosure },
};
</script>
