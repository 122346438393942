<template>
  <div class="py-4 w-full flex flex-col border-b border-gray-200">
    <button
      class="text-left w-full flex justify-between"
      @click="open = !open"
      :aria-expanded="open.toString()"
    >
      <h3 class="font-serif font-semibold text-2xl">{{ question }}</h3>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6 flex-none"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 4v16m8-8H4"
        />
      </svg>
    </button>
    <div :hidden="!open"><slot></slot></div>
  </div>
</template>

<script>
export default {
  props: ["question", "answer"],
  data() {
    return {
      open: false,
    };
  },
};
</script>
