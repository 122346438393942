<template>
  <div>
    <div class="z-50 fixed w-full flex justify-center">
      <!-- <div class="fixed bottom-0 lg:bottom-4 lg:right-4 w-full lg:w-alert">
        <Notice />
      </div> -->
    </div>
    <div id="app" class="flex justify-center relative overflow-x-hidden">
      <router-view />
      <div class="w-full sm:max-w-5xl relative font-sans">
        <Navbar />
        <Hero />
        <Showcase />
        <Values />
        <!-- <Comparison /> -->
        <FAQs />
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Home/Navbar/Navbar.vue";
import Hero from "../components/Home/Hero.vue";
import Showcase from "../components/Home/Showcase/Index.vue";
import Values from "../components/Home/Values/Index.vue";
// import Comparison from "../components/Home/Comparison/Comparison.vue";
import FAQs from "../components/Home/FAQs/Index.vue";
import Footer from "../components/Home/Footer.vue";
// import Notice from "../components/common/Notice.vue";
import router from "../router";
export default {
  name: "App",
  components: {
    Navbar,
    Hero,
    Showcase,
    Values,
    // Comparison,
    FAQs,
    Footer,
    // Notice,
  },
  data() {
    return {
      alertOpened: false,
      words: [
        "Financial Aid",
        "Tuition Assistance",
        "Individualized Tuition",
        "Net Revenue Modeling",
      ],
      selectedWord: "Financial Aid",
    };
  },
  methods: {
    submitSubscription(submitEvent) {
      const form = submitEvent.target;
      fetch(
        "https://XX.us14.list-manage.com/subscribe/post?u=369f4edb1c7301aea054c0804&id=f5e93eefc8",
        {
          method: "POST",
          mode: "no-cors",
          body: new URLSearchParams([...new FormData(form)]),
        }
      )
        .then(() => (this.alertOpened = true))
        .catch((error) => console.log("error: ", error));
    },
    navigateToPrivacy() {
      router.push({ path: "/privacy" });
    },
  },
  created() {
    setInterval(() => {
      const index = this.words.indexOf(this.selectedWord) + 1;
      if (this.words[index]) this.selectedWord = this.words[index];
      else this.selectedWord = this.words[0];
    }, 3000);
  },
};
</script>
