<template>
  <div class="flex w-full max-w-2xl items-center" style="height: 500px">
    <div
      class="lg:absolute lg:-right-showcaseRight flex flex-col lg:flex-row items-center lg:space-x-20"
    >
      <div class="order-2 lg:order-1 flex flex-col space-y-3 lg:max-w-md">
        <svg
          width="49"
          height="44"
          viewBox="0 0 49 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M30 19V17C30 16.4696 29.7893 15.9609 29.4142 15.5858C29.0391 15.2107 28.5304 15 28 15H18C17.4696 15 16.9609 15.2107 16.5858 15.5858C16.2107 15.9609 16 16.4696 16 17V23C16 23.5304 16.2107 24.0391 16.5858 24.4142C16.9609 24.7893 17.4696 25 18 25H20L30 19ZM22 29H32C32.5304 29 33.0391 28.7893 33.4142 28.4142C33.7893 28.0391 34 27.5304 34 27V21C34 20.4696 33.7893 19.9609 33.4142 19.5858C33.0391 19.2107 32.5304 19 32 19H22C21.4696 19 20.9609 19.2107 20.5858 19.5858C20.2107 19.9609 20 20.4696 20 21V27C20 27.5304 20.2107 28.0391 20.5858 28.4142C20.9609 28.7893 21.4696 29 22 29ZM29 24C29 24.5304 28.7893 25.0391 28.4142 25.4142C28.0391 25.7893 27.5304 26 27 26C26.4696 26 25.9609 25.7893 25.5858 25.4142C25.2107 25.0391 25 24.5304 25 24C25 23.4696 25.2107 22.9609 25.5858 22.5858C25.9609 22.2107 26.4696 22 27 22C27.5304 22 28.0391 22.2107 28.4142 22.5858C28.7893 22.9609 29 23.4696 29 24V24Z"
            stroke="#E46794"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <rect
            width="49"
            height="44"
            rx="8"
            fill="#E46794"
            fill-opacity="0.46"
          />
        </svg>

        <h3 class="font-serif font-semibold text-4xl">
          Dynamic, Real-Time Tracking of Net Tuition Revenue
        </h3>
        <p class="text-lg">
          Set enrollment and revenue goals and watch Clarity track your progress
          in real time as you enter awards and enrollment decisions are made.
        </p>
      </div>
      <img
        class="order-1 lg:order-2 w-3/4 lg:w-full"
        src="assets/Showcase4.svg"
      />
    </div>
  </div>
</template>

<script>
// import Hero from "./Hero.svg"
export default {
  // components: {
  //   Hero
  // }
};
</script>
