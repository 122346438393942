<template>
  <div class="px-6 lg:px-0 flex max-w-2xl items-center" style="height: 500px">
    <div class="w-full space-y-3 z-10">
      <h3 class="font-serif font-medium text-3xl">Bring Clarity to your</h3>
      <h2 class="font-bold text-5xl">{{selectedWord}}</h2>
      <p class="text-[20px] max-w-sm">
        The financial aid platform purpose built to remove enrollment barriers for today's families.
      </p>
      <div class="flex space-x-4 max-w-sm">
        <span
          class="wistia_embed wistia_async_y0j3unla9k popover=true popoverContent=link"
          style="display: inline; position: relative"
        >
          <button
            class="w-40 h-10 text-black font-normal rounded bg-gray-100 hover:bg-gray-200 flex justify-center items-center"
          >
            Watch Demo
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="ml-2 h-5 w-5 text-purplePrimary"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </span>
        <button
          class="w-32 h-10 text-white font-normal rounded bg-purplePrimary hover:bg-purpleDark shadow-md"
          @click="goToContact"
        >
          Get in touch
        </button>
      </div>
    </div>

    <div class="absolute -right-52">
      <img class="opacity-30 lg:opacity-100" src="assets/HeroIllustration.svg" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      words: [
        "Financial Aid",
        "Tuition Assistance",
        "Individualized Tuition",
        "Net Revenue Modeling",
      ],
      selectedWord: "Financial Aid",
    };
  },
  methods: {
    goToContact() {
      this.$router.push({ path: "/contact" });
    },
  },
  created() {
    setInterval(() => {
      const index = this.words.indexOf(this.selectedWord) + 1;
      if (this.words[index]) this.selectedWord = this.words[index];
      else this.selectedWord = this.words[0];
    }, 3000);
  },
};
</script>
