


<template>
  <div class="flex w-full max-w-2xl items-center" style="height: 500px">
    <div class="lg:absolute lg:-right-showcaseRight flex flex-col lg:flex-row items-center lg:space-x-20">
      <div class="order-2 lg:order-1 flex flex-col space-y-3 lg:max-w-md">
        <svg
          width="49"
          height="44"
          viewBox="0 0 49 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M37.081 15.8926L26.4864 26.4872L21.1891 21.1899L13.2432 29.1358M26.4864 15.8926H37.081H26.4864ZM37.081 15.8926V26.4872V15.8926Z"
            stroke="#46AB99"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <rect
            width="49"
            height="44"
            rx="8"
            fill="#90DED0"
            fill-opacity="0.46"
          />
        </svg>

        <h3 class="font-serif font-semibold text-4xl">
          Customizable Methodology to Ensure Fair, Consistent Awards
        </h3>
        <p class="text-lg">
          With over twenty customizable methodology settings, schools have the
          power and flexibility to align their awards with local conditions and
          school priorities.
        </p>
      </div>
      <img class="order-1 lg:order-2 w-3/4 lg:w-full" src="assets/Showcase3.svg" />
    </div>
  </div>
</template>

<script>
// import Hero from "./Hero.svg"
export default {
  // components: {
  //   Hero
  // }
};
</script>
