<template>
  <div class="mx-6 lg:mx-0 flex justify-between items-center h-28">
    <img src="assets/Logo.svg" />

    <!-- <div class="md:hidden">
      <Hamburger />
    </div> -->

    <div class="flex space-x-2">
      <button
        class="hidden lg:block w-32 h-10 text-white font-normal rounded bg-purplePrimary hover:bg-purpleDark shadow-md"
        @click="goToContact"
      >
        Get in touch
      </button>
      <a href="https://clarityapp.com">
        <button class="w-24 lg:w-32 h-8 lg:h-10 text-white font-medium rounded bg-purplePrimary hover:bg-purpleDark shadow-md">
          Sign in
        </button>
      </a>
    </div>
  </div>
</template>

<script>
// import Hamburger from "./Hamburger.vue";

export default {
  components: {
    // Hamburger
  },
  methods: {
    goToContact() {
      this.$router.push({ path: "/contact" });
    },
  },
};
</script>
