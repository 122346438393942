<template>
    <div class="px-6 lg:px-0 flex flex-col items-center space-y-16 my-28">
        <showcase-1 />
        <showcase-2 />
        <showcase-3 />
        <showcase-4 />
    </div>
</template>

<script>
import Showcase1 from "./Showcase1.vue"
import Showcase2 from "./Showcase2.vue"
import Showcase3 from "./Showcase3.vue"
import Showcase4 from "./Showcase4.vue"
export default {
    components: {
        Showcase1,
        Showcase2,
        Showcase3,
        Showcase4
    }
}
</script>