<template>
  <div class="flex w-full max-w-2xl items-center" style="height: 500px">
    <div
      class="lg:absolute lg:-left-showcaseLeft flex flex-col lg:flex-row items-center lg:space-x-20"
    >
      <img class="w-3/4 lg:w-full" src="assets/Showcase2.svg" />
      <div class="flex flex-col space-y-3 lg:max-w-md">
        <svg
          width="49"
          height="43"
          viewBox="0 0 49 43"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.7579 19.545V27.3632V19.545ZM24.7579 27.3632L20.8488 23.4541L24.7579 27.3632ZM24.7579 27.3632L28.667 23.4541L24.7579 27.3632ZM31.2731 33.8784H18.2428C17.5516 33.8784 16.8887 33.6038 16.4 33.1151C15.9113 32.6263 15.6367 31.9635 15.6367 31.2723V13.0299C15.6367 12.3387 15.9113 11.6759 16.4 11.1871C16.8887 10.6984 17.5516 10.4238 18.2428 10.4238H25.5215C25.8671 10.4239 26.1984 10.5612 26.4427 10.8056L33.4974 17.8602C33.7417 18.1045 33.8791 18.4359 33.8791 18.7815V31.2723C33.8791 31.9635 33.6046 32.6263 33.1158 33.1151C32.6271 33.6038 31.9643 33.8784 31.2731 33.8784Z"
            stroke="#EBB7B7"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <rect
            width="48.2121"
            height="43"
            rx="8"
            fill="#FDA8A8"
            fill-opacity="0.46"
          />
        </svg>

        <h3 class="font-serif font-semibold text-4xl">
          Smart Automation with Rules
        </h3>
        <p class="text-lg">
          Save time and create more consistent awards using Rules. Build conditional logic statements that generate proposed awards automatically.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// import Hero from "./Hero.svg"
export default {
  // components: {
  //   Hero
  // }
};
</script>
