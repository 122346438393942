<template>
  <div class="flex w-full max-w-2xl items-center" style="height: 500px">
    <div class="lg:absolute lg:-left-showcaseLeft flex flex-col lg:flex-row items-center lg:space-x-20">
      <img class="w-3/4 lg:w-full" src="assets/Showcase1.svg" />
      <div class="flex flex-col space-y-3 lg:max-w-md">
        <svg
          width="50"
          height="44"
          viewBox="0 0 50 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.926 11.0564C24.6759 10.8064 24.3368 10.666 23.9833 10.666C23.6297 10.666 23.2907 10.8064 23.0406 11.0564L13.7073 20.3897C13.4644 20.6412 13.33 20.978 13.3331 21.3276C13.3361 21.6772 13.4763 22.0116 13.7235 22.2588C13.9707 22.506 14.3052 22.6463 14.6548 22.6493C15.0044 22.6523 15.3412 22.5179 15.5926 22.2751L15.9833 21.8844V30.6657C15.9833 31.0194 16.1238 31.3585 16.3738 31.6085C16.6239 31.8586 16.963 31.9991 17.3166 31.9991H19.9833C20.3369 31.9991 20.6761 31.8586 20.9261 31.6085C21.1761 31.3585 21.3166 31.0194 21.3166 30.6657V27.9991C21.3166 27.6454 21.4571 27.3063 21.7071 27.0563C21.9572 26.8062 22.2963 26.6657 22.65 26.6657H25.3166C25.6702 26.6657 26.0094 26.8062 26.2594 27.0563C26.5095 27.3063 26.65 27.6454 26.65 27.9991V30.6657C26.65 31.0194 26.7904 31.3585 27.0405 31.6085C27.2905 31.8586 27.6297 31.9991 27.9833 31.9991H30.65C31.0036 31.9991 31.3427 31.8586 31.5928 31.6085C31.8428 31.3585 31.9833 31.0194 31.9833 30.6657V21.8844L32.374 22.2751C32.6254 22.5179 32.9622 22.6523 33.3118 22.6493C33.6614 22.6463 33.9958 22.506 34.243 22.2588C34.4903 22.0116 34.6305 21.6772 34.6335 21.3276C34.6366 20.978 34.5022 20.6412 34.2593 20.3897L24.926 11.0564V11.0564Z"
            fill="#6C63FF"
            fill-opacity="0.88"
          />
          <rect
            width="49.3333"
            height="44"
            rx="8"
            fill="#6C63FF"
            fill-opacity="0.46"
          />
        </svg>

        <h3 class="font-serif font-semibold text-4xl">
          An Easier Application for Today's Families
        </h3>
        <p class="text-lg">
          Our user-friendly, intuitive, mobile-responsive application can be completed in one sitting in 15 minutes or less. Instead of scanning, uploading or mailing prior year tax documents, families simply complete an e-signature form and we pull their verified 1040 directly from the IRS. 
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// import Hero from "./Hero.svg"
export default {
  // components: {
  //   Hero
  // }
};
</script>
