<template>
  <div class="flex items-center mt-40">
    <div class="px-6 lg:px-0 w-full space-y-3">
      <svg
        width="57"
        height="5"
        viewBox="0 0 57 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line y1="2.5" x2="57" y2="2.5" stroke="black" stroke-width="5" />
      </svg>
      <h2 class="font-bold text-5xl">The Values</h2>
      <div class="w-full flex flex-col md:flex-row justify-between space-y-6 md:space-y-0 pt-16">
        <div class="flex flex-col space-y-4 md:pr-4">
          <img class="w-20 h-20" src="assets/ValueImg1.svg" />
          <h4 class="text-xl font-semibold">
            Customizable Terminology for all Discount Types
          </h4>
          <p class="text-[20px] max-w-sm">
            Whether you offer financial aid, tuition assistance, flexible
            tuition, or something else, you can easily adjust the language on
            your platform to reflect the needs of your school. Also, customize
            your discount types and budgets to allow for real-time tracking of
            remission, scholarships, and more.
          </p>
        </div>

        <div class="flex flex-col space-y-4 md:pl-4">
          <img class="w-20 h-20" src="assets/ValueImg2.svg" />
          <h4 class="text-xl font-semibold">A Partner, not just a Product</h4>
          <p class="text-[20px] max-w-sm">
            We recognize the importance of the work our schools do and seek to
            be a true partner to them. Each of our school partners receives a
            dedicated Success Manager that walks them through onboarding and
            implementation and serves as an extension of their financial aid
            team.
          </p>
        </div>
      </div>

      <div class="w-full flex flex-col md:flex-row justify-between space-y-6 md:space-y-0 pt-6 md:pt-16">
        <div class="flex flex-col space-y-4 md:pr-4">
          <img class="w-20 h-20" src="assets/ValueImg3.svg" />
          <h4 class="text-xl font-semibold">
            Reduce Barriers and Increase Accessibility
          </h4>
          <p class="text-[20px] max-w-sm">
            Affordability is one of the biggest barriers to accessing a private
            school education and the financial aid process can seem daunting,
            especially to first time private school applicants. Clarity
            helps to reduce friction and make your school accessible and
            approachable for more families.
          </p>
        </div>

        <div class="flex flex-col space-y-4 md:pl-4">
          <img class="w-20 h-20" src="assets/ValueImg4.svg" />
          <h4 class="text-xl font-semibold">
            Streamlined Process for Returning Families
          </h4>
          <p class="text-[20px] max-w-sm">
            Families only fill out the full Clarity application once. For
            returning families, the system generates a custom diagnostic
            questionnaire to assess what things may have changed and then
            generates a simplified, custom application that covers only the
            things that have changed since the previous year.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
