<template>
  <div
    class="px-6 lg:px-0 flex flex-col lg:flex-row space-y-3 lg:space-y-0 justify-between border-t-2 border-black mt-20 py-10"
  >
    <div>
      <img src="assets/Logo.svg" />
      <p>@2022 Clarity Financial, Inc.</p>
      <p class="cursor-pointer hover:text-blue-400" @click="navigateToPrivacy">
        Privacy Policy
      </p>
    </div>

    <div class="lg:text-right text-sm lg:text-base">
      <p>info@claritytuition.com</p>
      <p>(301) 327-4761</p>
      <p>16825 48th Ave W #319, Lynnwood, WA 98037</p>
    </div>
  </div>
</template>

<script>
import router from "../../router";

export default {
  methods: {
    navigateToPrivacy() {
      router.push({ path: "/privacy" });
    },
  },
};
</script>
